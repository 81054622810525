import NextTagComponent from "@/components/sharedComponents/nextTag/NextTag.component";
import {
  InventoryBatch,
  InventoryLocationSplit,
  SplitPayload
} from "@/interfaces/batch";
import { Room } from "@/interfaces/room";
import { metrcEnabled } from "@/router.utils";
import { traceabilityService } from "@/services/traceability.service";
import { mathJs } from "@/utils/math.utils";
import { NumericPickerComponent } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { SummaryData } from "../../adjust/models/batch-adjust.model";
import SidebarListComponent from "../../common/BatchSideBarList/SidebarList.component";
import { SplitItem } from "../batch-split.model";
import SplitOverviewComponent from "../split-overview/SplitOverview.component";
import Template from "./SplitManager.template.vue";

@Component({
  mixins: [Template],
  components: {
    "list-component": SidebarListComponent,
    "numeric-picker": NumericPickerComponent,
    "split-overview": SplitOverviewComponent,
    NextTagComponent
  }
})
export default class SplitManagerComponent extends Vue {
  @Prop({ required: true }) public listItems!: InventoryBatch[];
  @Prop({ required: true }) public value!: SplitItem[];
  @Prop({ default: false }) public isFinished!: boolean;
  @Prop({ required: true }) public current!: number;
  @Prop({ default: () => [] }) public summaryData!: SummaryData[];
  @Prop({ default: () => [] }) public rooms!: Room[];
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public createCount = 0;
  public targetRoomId: number | null = null;
  public hasMetrc = metrcEnabled();

  /**
   * Delete element selected in formData
   * @params i: index from viewData j: index from formData
   * @returns void
   */
  public deleteSplitItem(i: number, j: number) {
    const inventoryLocations = this.formData[this.current].form[j]
      .inventory_locations;
    const selectedQuantity = this.formData[this.current].form[j]
      .inventory_locations[i].quantity;
    this.formData[this.current].form[j].remaining = mathJs.add!(
      mathJs.bignumber!(this.formData[this.current].form[j].remaining),
      mathJs.bignumber!(selectedQuantity)
    ) as number;
    inventoryLocations.splice(i, 1);
    this.createCount--;
    this.updateFormData();
  }

  /**
   * Creates new element in formData with the new split
   * @params i: index from rooms j: index from formData
   * @returns void
   */
  public addNewSplitItem(i: number, j: number) {
    const inventoryLocations = this.formData[this.current].form[j]
      .inventory_locations;
    const selectedQuantity = this.formData[this.current].form[j]
      .inventory_locations[i].quantity;
    this.formData[this.current].form[j].remaining = mathJs.subtract!(
      mathJs.bignumber!(this.formData[this.current].form[j].remaining),
      mathJs.bignumber!(selectedQuantity)
    ) as number;
    inventoryLocations.push(cloneDeep(inventoryLocations[i]));
    inventoryLocations[i + 1].quantity = 0;
    inventoryLocations[i + 1].tracking_id = "";
    inventoryLocations[i].isEditing = false;
    this.createCount++;
    this.updateFormData();
  }

  public disablePicker(quantity: number, remaining: number) {
    return [
      (quantity && quantity > 0) || "Invalid",
      (quantity && quantity <= remaining) || "Invalid"
    ];
  }

  public onSplitBatches() {
    const batchUID = this.formData[this.current].batchId;
    const payload: SplitPayload[] = this.formData[this.current].form
      .filter(form => form.quantity !== form.remaining)
      .map(form => {
        return {
          from_inventory_location_id: form.inventory_location_id,
          inventory_locations: form.inventory_locations
            .filter(item => item.quantity !== 0 && !item.isEditing)
            .map(item => {
              const formatted: InventoryLocationSplit = {
                batch_uid: batchUID,
                inventory_location_id: this.targetRoomId!,
                quantity: item.quantity
              };
              if (item.tracking_id) {
                formatted.tracking_id = item.tracking_id;
              }
              return formatted;
            })
        };
      });
    this.$emit("onSplit", payload);
  }

  public updateFormData() {
    this.$emit("input", this.formData);
  }

  public get formData() {
    return cloneDeep(this.value);
  }

  public get isProductCannabic(): boolean {
    return Boolean(
      this.listItems[this.current] &&
        this.listItems[this.current].product &&
        this.listItems[this.current].product!.marijuana
    );
  }
}
