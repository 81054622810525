import { Component, Prop, Vue } from "vue-property-decorator";
import { SummaryData } from "../batch-split.model";
import Template from "./SplitOverview.template.vue";
@Component({
  mixins: [Template]
})
export default class SplitOverviewComponent extends Vue {
  @Prop({ default: [] }) public summaryData!: SummaryData[];
  public batchLabel = {
    product_name: "batches.product",
    strain: "strain.strain",
    batch_type: "batches.batch_type",
    batch_uid: "batches.batch_id",
    secondary_id: "batches.secondary_id",
    usable_weight_value: "batches.usable_weight",
    total_quantity: "batches.starting_quantity",
    new_quantity: "batches.new_quantity"
  };
  public splitLabel = {
    product_name: "batches.product",
    batch_uid: "batches.batch_id",
    secondary_id: "batches.secondary_id",
    created_from: "batches.created_from",
    destination_room: "batches.destination_room",
    usable_weight_value: "batches.usable_weight",
    quantity: "batches.quantity"
  };
}
