import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { EventBus } from "@/event-bus";
import {
  BatchAction,
  CustomBatch,
  InventoryBatch,
  SplitPayload
} from "@/interfaces/batch";
import {
  BatchOperation,
  SplitCreatedItems
} from "@/interfaces/batchOperations";
import { Room } from "@/interfaces/room";
import { i18n } from "@/plugins/i18n";
import { metrcEnabled } from "@/router.utils";
import { batchOperationsService } from "@/services/batchOperations.service";
import { messagesService } from "@/services/messages.service";
import { PageNavAction } from "@/types/types";
import { mapBatchesToAction } from "@/utils/batch-actions.utils";
import { mathJs, sumArray } from "@/utils/math.utils";
import { AxiosError } from "axios";
import {
  ActionsSubheaderComponent,
  CallbackPromise
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import findLastIndex from "lodash/findLastIndex";
import flatten from "lodash/flatten";
import keys from "lodash/keys";
import some from "lodash/some";
import startsWith from "lodash/startsWith";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import QuantitiesReservationComponent from "../common/QuantitiesReservation/QuantitiesReservation.component";
import {
  SplitForwardResponse,
  SplitItem,
  SummaryData
} from "./batch-split.model";
import SplitManagerComponent from "./split-manager/SplitManager.component";
import Template from "./SplitBatch.template.vue";

@Component({
  mixins: [Template],
  components: {
    "split-manager": SplitManagerComponent,
    "quantities-reserv": QuantitiesReservationComponent
  }
})
export default class SplitBatchComponent extends Vue {
  @Action("loadAllRooms", { namespace: "RoomModule" })
  public loadRooms!: CallbackPromise<void>;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @Getter("allRooms", { namespace: "RoomModule" })
  public allRooms!: Room[];
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public loading: boolean = false;
  /** Original batches data
   * Once set in created lifecycle it must not change
   * since it's the only source of truth.
   */
  public batches: InventoryBatch[] = [];
  /** Data that will be manipulated by the user */
  public formData: SplitItem[] = [];
  /** Payload for API */
  public summaryData: SummaryData[] = [];
  public batchIndex = 0;
  public isFinished = false;
  public formattedBatches: BatchAction<CustomBatch> | null = null;
  public reservationData: BatchOperation | null = null;
  public step = 0;
  public canReserve = true;
  public splitResultLog: SplitCreatedItems[] = [];
  public hasMetrc = metrcEnabled();

  public back() {
    this.$router.back();
  }
  // Added  biotrack trace conditions on hitting API
  public async onSplit(forwardItems: SplitPayload[]) {
    this.loading = true;

    const payload = flatten(forwardItems.map(fi => fi.inventory_locations));
    const response: SplitForwardResponse = await batchOperationsService.forwardSplit(
      this.reservationData!.operation_uid,
      payload,
      this.showLoadingWindow
    );
    if (response) {
      const errorMessage: string = String(
        response!.errors! &&
          response!.errors!.response &&
          response!.errors!.response!.data &&
          response!.errors!.response!.data.error.message
      );
      // If  api fails in case of trace is enabled we need to display those errors , but we need to bypass that condition when pin is wrong
      if (
        (this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled
          ? errorMessage === "Forbidden: wrong pin code"
          : !!response.errors || !response.success) ||
        !response.pinCode
      ) {
        this.loading = false;
        EventBus.$emit("removePopup", true);
        return false;
      }
      if (response.success) {
        if (this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled) {
          EventBus.$emit("removePopup", true);
          // If API is success it'll show move pop loading UI
          this.renderTraceabilityModal(
            i18n.t("biotrack_traceability.duplicate_title").toString(),
            i18n.t("biotrack_traceability.move_loading_message").toString(),
            i18n.t("biotrack_traceability.move_loading_description").toString(),
            "loading",
            false,
            false,
            ""
          );
          setTimeout(() => {
            EventBus.$emit("removePopup", true);
          }, 2000);
          if (response.data!.type === "moveapi") {
            setTimeout(() => {
              EventBus.$emit("removePopup", true);
              this.renderTraceabilityModal(
                i18n.t("biotrack_traceability.move_batch_title").toString(),
                i18n.t("biotrack_traceability.move_batch_failed").toString(),
                i18n
                  .t("biotrack_traceability.move_batch_failed_description")
                  .toString() + response.data!.error,
                "",
                true,
                false,
                "Ok"
              );
            }, 2000);
          }
        } else {
          this.showForwardSuccess();
        }
      } else {
        if (this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled) {
          // If We get move api error ,then we need to show its loading UI and show move api error else only error in split error
          if (errorMessage.includes("moveapi")) {
            EventBus.$emit("removePopup", true);
            this.renderTraceabilityModal(
              i18n.t("biotrack_traceability.duplicate_title").toString(),
              i18n.t("biotrack_traceability.move_loading_message").toString(),
              i18n
                .t("biotrack_traceability.move_loading_description")
                .toString(),
              "loading",
              false,
              false,
              ""
            );
            setTimeout(() => {
              EventBus.$emit("removePopup", true);
              this.renderTraceabilityModal(
                i18n.t("biotrack_traceability.move_batch_title").toString(),
                i18n.t("biotrack_traceability.move_batch_failed").toString(),
                i18n
                  .t("biotrack_traceability.move_batch_failed_description")
                  .toString() + errorMessage,
                "",
                true,
                false,
                "Ok"
              );
            }, 2000);
          } else {
            EventBus.$emit("removePopup", true);
            this.renderTraceabilityModal(
              i18n.t("biotrack_traceability.split_batch_title").toString(),
              i18n.t("biotrack_traceability.split_batch_failed").toString(),
              i18n
                .t("biotrack_traceability.split_batch_failed_description")
                .toString() + errorMessage,
              "",
              true,
              false,
              "Ok"
            );
          }
        } else {
          this.showForwardErrors(response.errors!);
        }
      }

      this.updateSplitResultsLog(response, forwardItems);
      this.updateBatches(response);

      // Check if there are more batches to work with
      if (this.batches[this.batchIndex + 1]) {
        this.batchIndex++;
      } else if (!this.hasMetrc || response.success) {
        this.onSplitFinish();
      }
    }
    this.loading = false;
  }

  /**
   * Clean viewData to show information in the summary
   */
  public async onSplitFinish() {
    this.loading = true;
    // passed one more paramter which is a boolean type to know if trace is on
    const response = await batchOperationsService.finishSplit(
      this.reservationData!.operation_uid,
      this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled
    );
    if (response) {
      if (some(this.batches, "isDone")) {
        this.isFinished = true;
        this.reservationData = this.addErrorBatchesToSummary(response);
        this.createSummary();
      } else {
        this.back();
      }
    }
    this.loading = false;
  }

  public updateReservationData(e: {
    reservation: BatchAction<CustomBatch>;
    valid: boolean;
  }) {
    this.formattedBatches = e.reservation;
    this.canReserve = e.valid;
  }

  public async reserveQuantities() {
    this.loading = true;
    this.reservationData = await batchOperationsService.createOperation(
      this.formattedBatches!,
      "split"
    );
    if (this.reservationData) {
      this.buildForm();
      this.batches! = this.batches!.filter((batch: any) => {
        if (this.formattedBatches!.batches[batch.batch_uid][0].selected!) {
          return batch;
        }
      });
      this.step = 1;
    }
    this.loading = false;
  }

  public async created() {
    this.setPageNav({
      title: "batches.batch_split",
      rightActions: {
        generalActions: () => [
          {
            visibleCondition: () => !this.isFinished && !!this.step,
            action: this.onSplitFinish,
            buttonText: "batches.finish",
            vuetifyProps: () => ({
              loading: this.loading
            })
          },
          {
            visibleCondition: () => !this.isFinished && !this.step,
            action: this.reserveQuantities,
            buttonText: "batch_convert.reserve",
            vuetifyProps: () => ({
              disabled: !this.canReserve,
              loading: this.loading
            })
          },
          {
            visibleCondition: () => this.isFinished,
            action: this.back,
            buttonText: "batches.back_to_inventory",
            vuetifyProps: () => ({
              disabled: !this.isFinished
            })
          }
        ]
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              visibleCondition: () => !this.step,
              icon: "fal fa-chevron-left",
              action: this.back
            }
          ]
        }
      }
    });

    if (!this.allRooms.length) {
      this.loadRooms();
    }

    this.batches = JSON.parse(this.$route.params.batches);
    this.formattedBatches = mapBatchesToAction(this.batches);
  }

  protected showLoadingWindow(): void {
    if (this.hasMetrc && this.hasMarijuana()) {
      this.$modals
        .load(LoadingWindowComponent, {
          closable: false,
          size: "fit",
          positionY: "center",
          positionX: "center",
          backdrop: true
        })
        .catch(() => {
          if (every(this.batches, "isDone")) {
            this.onSplitFinish();
          }
        });
    }
    if (this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled) {
      // Split Loading Popup
      this.renderTraceabilityModal(
        i18n.t("biotrack_traceability.duplicate_title").toString(),
        i18n.t("biotrack_traceability.split_loading_message").toString(),
        i18n.t("biotrack_traceability.split_loading_description").toString(),
        "loading",
        false,
        false,
        ""
      );
    }
  }

  protected hasMarijuana(): boolean {
    return !!(
      this.batches[this.batchIndex] &&
      this.batches[this.batchIndex].product_variant &&
      this.batches[this.batchIndex].product_variant.marijuana
    );
  }

  protected showForwardSuccess() {
    if (this.hasMetrc && this.hasMarijuana()) {
      EventBus.$emit("mtrcLoadingEvent", {
        show: false
      });
    }
    messagesService.renderSuccessMessage("batch_move.success");
  }

  protected showForwardErrors(errors: AxiosError) {
    if (this.hasMetrc && this.hasMarijuana()) {
      EventBus.$emit("mtrcLoadingEvent", {
        show: true,
        errorList: messagesService.parseMetrcError(errors)
      });
    } else {
      messagesService.renderErrorMessage(errors);
    }
  }

  protected updateBatches(response: SplitForwardResponse) {
    this.batches[this.batchIndex].isDone = true;
    this.batches[this.batchIndex].hasErrors = !response.success;
  }

  protected updateSplitResultsLog(
    response: SplitForwardResponse,
    forwardItems: SplitPayload[]
  ): void {
    let errorsString: string[] = [];

    if (!response.success) {
      errorsString = messagesService
        .parseMetrcError(response.errors!)
        .map(error => {
          return error.message;
        });

      const inventorySplit = flatten(
        forwardItems.map(fi => {
          return fi.inventory_locations.map(item => {
            return {
              from_inventory_location_id: fi.from_inventory_location_id,
              ...item
            };
          });
        })
      );

      const splitResult = inventorySplit.map(is => {
        const original = this.formattedBatches!.batches[is.batch_uid].find(
          r => r.room_id === is.from_inventory_location_id
        );

        return {
          batch_uid: is.batch_uid,
          quantity_value: is.quantity,
          quantity_unit: original!.unit,
          quantity: `${is.quantity}${original!.unit}`,
          result_batch_uid: "--",
          inventory_location_id: is.inventory_location_id,
          tracking_id: is.tracking_id,
          origin: [
            {
              batch_uid: original!.batch_uid || "",
              batch_fraction_uid: "",
              sku: original!.product_sku || "",
              tracking_id: original!.tracking_id,
              quantity_value: original!.room_quantity_editable,
              quantity_unit: original!.unit,
              inventory_location_id: original!.room_id
            }
          ],
          hasErrors: !response.success,
          errors: errorsString
        };
      });

      this.splitResultLog = [...this.splitResultLog, ...splitResult];
    }
  }

  protected addErrorBatchesToSummary(response: BatchOperation): BatchOperation {
    const reservationData = cloneDeep(response);
    let splits =
      reservationData.stage.params.splits!.map(split => {
        return {
          ...split,
          hasErrors: false,
          errors: []
        } as SplitCreatedItems;
      }) || [];

    this.batches.forEach((batch, index) => {
      if (batch.hasErrors) {
        const batchSplits = this.splitResultLog.filter(
          s => s.batch_uid === batch.batch_uid
        );

        if (index === 0) {
          splits = [...batchSplits, ...splits];
        } else if (index === this.batches.length - 1) {
          splits = [...splits, ...batchSplits];
        } else {
          const chunk = findLastIndex(
            splits,
            s => s.batch_uid === this.batches[index - 1].batch_uid
          );
          splits = [...splits.splice(0, chunk + 1), ...batchSplits, ...splits];
        }
      }
    });

    reservationData.stage.params.splits = splits;
    return reservationData;
  }

  /**
   * Creates summary and updates sidebarList to show only used batches
   */
  protected createSummary() {
    const splits =
      (this.reservationData && this.reservationData.stage.params.splits!) || [];
    this.summaryData = splits.reduce((acc: SummaryData[], item) => {
      const inRawData = this.formattedBatches!.batches[item.batch_uid].find(
        r =>
          r.room_id === item.origin[0].inventory_location_id &&
          r.batch_uid === item.origin[0].batch_uid
      );
      const forTrace = item.origin[0].biotrack_traceability_id;
      const inSummaryIndex = acc.findIndex(
        fd => fd.batch_uid === item.batch_uid
      );
      const destinationRoom = this.allRooms.find(
        r => r.id === item.inventory_location_id
      );
      const fromRoom = this.allRooms.find(
        r => r.id === item.origin[0].inventory_location_id
      );
      if (inSummaryIndex !== -1) {
        acc[inSummaryIndex].split_batches.push({
          product_name: inRawData!.product_name,
          batch_uid: item.result_batch_uid,
          secondary_id: item!.tracking_id || "--",
          created_from: fromRoom!.name,
          destination_room: destinationRoom ? destinationRoom.name : "--",
          usable_weight_value: inRawData!.usable_weight_value
            ? inRawData!.usable_weight_value +
              (inRawData!.usable_weight_unit || "u")
            : "--",
          quantity: item.quantity
        });
      } else {
        const startingQuantity = sumArray(
          this.formattedBatches!.batches[item.batch_uid],
          "room_quantity"
        );
        const newQuantity =
          startingQuantity -
          splits.reduce((res, split) => {
            if (split.batch_uid === item.batch_uid && split.quantity_value) {
              return mathJs.add!(
                res,
                mathJs.bignumber!(split.quantity_value)
              ) as number;
            }
            return res;
          }, 0);
        acc.push({
          product_name: inRawData!.product_name,
          strain: inRawData!.strain!,
          batch_type: inRawData!.batch_type!,
          batch_uid:
            this.hasBioTrackTraceIntegrations &&
            this.bioTrackTraceEnabled &&
            forTrace !== null
              ? forTrace
              : item.batch_uid,
          secondary_id: inRawData!.tracking_id || "--",
          usable_weight_value: inRawData!.usable_weight_value
            ? inRawData!.usable_weight_value +
              (inRawData!.usable_weight_unit || "u")
            : "--",
          total_quantity: startingQuantity,
          new_quantity: newQuantity + inRawData!.unit,
          split_batches: [
            {
              product_name: inRawData!.product_name,
              batch_uid:
                this.hasBioTrackTraceIntegrations &&
                this.bioTrackTraceEnabled &&
                item.result_batch_traceability_id !== ""
                  ? item.result_batch_traceability_id
                  : item.result_batch_uid,
              secondary_id: item!.tracking_id || "--",
              created_from: fromRoom!.name,
              destination_room: destinationRoom ? destinationRoom.name : "--",
              usable_weight_value: inRawData!.usable_weight_value
                ? inRawData!.usable_weight_value +
                  (inRawData!.usable_weight_unit || "u")
                : "--",
              quantity: item.quantity
            }
          ],
          hasErrors: !!item.hasErrors,
          errors: item.errors! || []
        });
      }
      return acc;
    }, []);
  }

  /**
   * Build formData and batch list data for step 1
   */
  protected buildForm() {
    const reservationItems =
      (this.reservationData &&
        this.reservationData.reservation.reservation_items) ||
      [];
    const rawBatchKeys = keys(this.formattedBatches!.batches);
    this.formData = reservationItems.reduce((acc: SplitItem[], item) => {
      const batchUId = rawBatchKeys.find(k =>
        startsWith(item.batch_fraction_uid, k)
      );
      if (batchUId) {
        const inRawData = this.formattedBatches!.batches[batchUId].find(
          r => r.room_id === item.inventory_location_id
        );
        const inFormDataIndex = acc.findIndex(fd => fd.batchId === batchUId);
        if (inFormDataIndex !== -1) {
          acc[inFormDataIndex].form.push({
            inventory_location_id: item.inventory_location_id,
            quantity: item.quantity_value,
            unit: item.quantity_unit || "u",
            remaining: item.quantity_value,
            room_name: inRawData!.room_name,
            inventory_locations: [
              {
                inventory_location_id: null,
                quantity: 0,
                isEditing: true
              }
            ]
          });
        } else {
          const newItem: SplitItem = {
            batchId: batchUId,
            form: [
              {
                inventory_location_id: item.inventory_location_id,
                quantity: item.quantity_value,
                unit: item.quantity_unit || "u",
                remaining: item.quantity_value,
                room_name: inRawData!.room_name,
                inventory_locations: [
                  {
                    inventory_location_id: null,
                    quantity: 0,
                    isEditing: true
                  }
                ]
              }
            ],
            isSelected: false,
            quantity: item.quantity_value
          };
          acc.push(newItem);
        }
      }
      return acc;
    }, []);
  }

  protected async renderTraceabilityModal(
    titleName: string,
    statusName: string,
    descriptionName: string,
    loader: string,
    accept: boolean,
    cancel: boolean,
    acceptValue: string
  ) {
    this.$modals.load(
      TraceabilityConfirm,
      { size: "normal", positionX: "center", positionY: "center" },
      {
        modalData: {
          status: {
            name: this.$t(statusName),
            style: "fontSize:27px ; fontWeight:600;"
          },
          description: {
            name: this.$t(descriptionName),
            style: "fontSize:23px ;"
          },
          title: {
            name: this.$t(titleName),
            style: "fontSize:30px ; letter-spacing: 0px;"
          },
          titleAvatar: {
            name: "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
            size: 80
          },
          loading: this.$t(loader),
          acceptButton: accept,
          cancelButton: cancel,
          acceptButtonValue: acceptValue
        }
      }
    );
  }
}
