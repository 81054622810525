import { render, staticRenderFns } from "./SplitBatch.template.vue?vue&type=template&id=a3e30232&scoped=true&"
var script = {}
import style0 from "./SplitBatch.template.vue?vue&type=style&index=0&id=a3e30232&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3e30232",
  null
  
)

export default component.exports